<template>
  <BaseCard title="Total Price" :edit="false">
    <v-container>
      <div v-if="isLoading">
        <v-progress-linear indeterminate></v-progress-linear>
      </div>
      <div v-else>
        {{ "$" + totalPrice.toFixed(2) }}
      </div>
      <br />
      <small>Total price does not include shipping charges.</small>
    </v-container>
  </BaseCard>
</template>

<script>
import BaseCard from "@/components/samples/ReviewStepCards/BaseCard";
import { mapActions, mapState } from "vuex";
export default {
  name: "Price",
  components: { BaseCard },
  data: () => ({
    isLoading: true
  }),
  computed: {
    ...mapState("samples", ["active_sample"]),
    totalPrice() {
      return this.active_sample.base_list_price;
    }
  },
  methods: {
    ...mapActions("samples", ["getSamples"])
  },
  async mounted() {
    this.isLoading = true;
    await this.getSamples(this.active_sample.id);
    this.isLoading = false;
  }
};
</script>

<style scoped></style>
